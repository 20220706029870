.background {
    height: 100%;
    background-image: url("/src/images/event/hololool_vuur.jpg");
    background-size: cover;
    background-position: center;
}

.tombolaTile {
    padding: 10px;
    background-color: #f0f2f5 !important;
    color: black !important;
}