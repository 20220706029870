/* Card container */
.tile-wrapper {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: 1px solid #FDA50F;
    height: 250px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgb(0,0,0,0.75);
}

/* Card header */
.tile-header {
    background: linear-gradient(90deg, rgba(255,128,0,1), rgba(255,165,0,1), rgba(255,191,0,1));
    color: #ffffff !important;
    text-align: center;
    font-size: 18px;
    font-weight: bold;

}

/* Card content */
.tile-content {
    height: 100%;
    background-size: cover;
    background-position: center;
}