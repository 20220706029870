.lineUpItem {
    height: 100px;
}

.lineUpItemText {
    font-size: 40px;
    font-weight: 800;
    padding-top: 25px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.ant-timeline-item-head {
    background-color: #f0f2f5 !important;
}

.ant-timeline-item {
    font-size: 20px !important;
}

.ant-timeline-item-label {
    padding-right: 10px;
}

.commonHeader {
    height: 100%;
    background-image: url("/src/images/event/hololool_1.jpg");
    background-size: cover;
    background-position: center;
}

.ambianceTentHeader {
    height: 100%;
    background-image: url("/src/images/event/hololool_6.jpg");
    background-size: cover;
    background-position: center;
}

.fuifTentHeader {
    height: 100%;
    background-image: url("/src/images/event/dj.jpg");
    background-size: cover;
    background-position: center;
}

.lineUpContent {
    background-color: #f0f2f5 !important;
}

.lineUpWrapper {
    min-height: 60vh;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
