.sponsor {
    height: 100px;
    background-color: white;
    border: 2px solid #f0f2f5;
    border-radius: 10px;
    padding: 2px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
