.footer {
    background-color: #000000 !important;
    text-align: center;
    color: white !important;
}

.header {
    background-color: #000000 !important;
    color: #FDA50F;
    font-weight: bold;
    position: fixed;
    z-index: 1;
    width: 100%;
}

.menu {
    background-color: #f0f2f5 !important;
}

.layout {
    background-color: #000000 !important;
}

.body {
    text-align: center;
    margin: auto;
    color: white;
}

.sponsors {
    background-color: #f0f2f5 !important;
}

.socials {
    float: right;
}

.socialCol {
    padding: 5px;
    color: white;
}

.info {
    text-align: center;
    margin: auto;
    color: white;
}

.infoRow {
    font-weight: bold;
    /*background-color: #434343 !important;*/
    position: fixed;
    z-index: 1;
    width: 100%;
    background: linear-gradient(90deg, rgba(255,128,0,1), rgba(255,165,0,1), rgba(255,191,0,1));
}

.divider {
    background: linear-gradient(90deg, rgba(255,191,0,1), rgba(255,165,0,1), rgba(255,128,0,1));
    height: 10px;
    width: 100%;
}

.menuItem {
    text-align: center;
    cursor: pointer;
    height: 50px;
}

.activeMenuItem {
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #FDA50F;
    height: 50px;
}

.menuItem:hover {
    color: #A10000;
}

.activeMenuItem:hover {
    color: #A10000;
    border-bottom: 1px solid #A10000;
}