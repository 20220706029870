.background {
    height: 100%;
    background-image: url("/src/images/event/hololool_vuur.jpg");
    background-size: cover;
    background-position: center;
}

.infoTileLeft {
    padding: 10px;
    background-color: #f0f2f5 !important;
    text-align: left;
}

.infoTileRight {
    padding: 10px;
    background-color: #f0f2f5 !important;
    text-align: right;
}

.tile {
    height: 250px !important;
    background-color: #000000;
}

.overlay {
    background-color: black !important;
    z-index: 1000 !important;
}

.clickableTile {
    height: 250px !important;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #FDA50F;
}

.tileTextFakkeltocht {
    font-size: 25px;
    font-weight: 700;
    padding-top: 60px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: red;
    transform: rotateY(0deg) rotate(-35deg); /* needs Y at 0 deg to behave properly*/
}

.tileTextVivaldiFakkeltocht {
    font-family: Vivaldi;
    font-size: 40px;
    font-weight: 800;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.tileText {
    font-family: Vivaldi;
    font-size: 40px;
    font-weight: 800;
    padding-top: 100px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.logo {
    height: 100%;
    background-image: url("/src/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    border: 1px solid #FDA50F;
}